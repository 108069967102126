<template>
  <section>
    <b-row>
      <b-col lg="4" class="ll-spanBox">
        <span>{{ details.orderNo }}</span>
      </b-col>
      <b-col lg="4" class="ll-spanBox">
        <span>{{ latestOrderStatus }}</span>
      </b-col>
    </b-row>
    <b-row class="ll-hr">
      <b-col lg="3" class="ll-Sedfloor ll-line">
        <p class="ll-pTit">{{ $t('General') }}</p>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Customer') }}:</label>
          <span class="ll-span">{{baseInfo.customerName}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Order date') }}:</label>
          <span class="ll-span">{{baseInfo.orderCreationTime}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Shipment term') }}:</label>
          <span class="ll-span">{{baseInfo.shipmentTerm}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Payment method') }}:</label>
          <span class="ll-span">{{baseInfo.paymentMethod}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Total SKU') }}:</label>
          <span class="ll-span">{{baseInfo.totalSku}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Total items') }}:</label>
          <span class="ll-span">{{baseInfo.totalItems}}</span>
        </div>
      </b-col>
      <b-col lg="3" class="ll-Sedfloor ll-line">
        <p class="ll-pTit">{{ $t('Order worksheet') }}</p>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Warehouse') }}:</label>
          <span class="ll-span">{{baseInfo.warehouseCode}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Creation Date') }}:</label>
          <span class="ll-span">{{baseInfo.createdAt}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Order Type') }}:</label>
          <span class="ll-span">{{baseInfo.orderType}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Logistics provider') }}:</label>
          <span class="ll-span">{{baseInfo.logisticsCode}}</span>
        </div>
      </b-col>
      <b-col lg="6" class="ll-Sedfloor">
        <b-row>
          <b-col lg="6">
            <p class="ll-pTit">{{ $t('Ship from') }}</p>
            <div class="ll-p1">
              <p>{{ baseInfo.senderAddress }}</p>
              <p>{{ baseInfo.senderDistict }}</p>
              <p>{{ baseInfo.senderCity }}</p>
              <p>{{ baseInfo.senderCountryCode }}</p>
              <p>{{ baseInfo.senderName }}</p>
              <p>{{ baseInfo.senderCompany }}</p>
              <p>{{ baseInfo.senderEmail }}</p>
              <p>{{ baseInfo.senderPhone }}</p>
              <p>{{ baseInfo.senderPostal }}</p>
              <p>{{ baseInfo.senderState }}</p>
            </div>
          </b-col>
          <b-col lg="6">
            <p class="ll-pTit">{{ $t('Ship to') }}</p>
            <div class="ll-p1">
              <p>{{ baseInfo.cneeName }}</p>
              <p>{{ baseInfo.cneeDistrict }}</p>
              <p>{{ baseInfo.cneeCity }}</p>
              <p>{{ baseInfo.cneeCountryCode }}</p>
              <p>{{ baseInfo.cneeCompany }}</p>
              <p>{{ baseInfo.cneeAddress }}</p>
              <p>{{ baseInfo.cneePhone }}</p>
              <p>{{ baseInfo.cneePostal }}</p>
              <p>{{ baseInfo.cneeState }}</p>
              <p>{{ baseInfo.cneeEmail }}</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    details: Object,
  },
  data() {
    return {
      latestOrderStatus: '',
      orderNo1: '',
      baseInfo: {
        cneeName: '',
        orderCreationTime: '',
        shipmentTerm: '',
        paymentMethod: '',
        totalSku: '',
        totalItems: '',
        warehouseCode: '',
        createdAt: '',
        orderType: '',
        logisticsCode: '',
        cneeDistrict: '',
        cneeCity: '',
        cneeCountryCode: '',
        cneeCompany: '',
        cneeAddress: '',
        cneePhone: '',
        cneePostal: '',
        cneeState: '',
        cneeEmail: '',
        senderAddress: '',
        senderDistict: '',
        senderCity: '',
        senderCountryCode: '',
        senderName: '',
        senderCompany: '',
        senderEmail: '',
        senderPhone: '',
        senderPostal: '',
        senderState: '',
        customerName: '',
      },
    }
  },
  computed: {},
  watch: {
    details(val) {
      this.getInfo(val)
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getInfo(info){
      this.baseInfo = info
    },
    getDetail(id) {
      this.$http.get('orderWms/getDetail', { params: { id: id } }).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          // console.log('res1', info)
          this.orderNo1 = info.orderNo
          this.baseInfo = info
        }
      }).catch(error => console.log(error))
    },
  },
}
</script>
<style scoped>
.ll-spanBox{
  margin: 10px 0;
}
.ll-spanBox span{
  font-size: 18px;
  font-weight: bold;
}
.ll-box{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.ll-box .ll-lab{
  color: #6e6b7b;
}
.ll-pTit{
  color: #ed6d01;
  font-size: 14px;
}
.ll-line{
  border-right: 1px solid #d0d2d6;
}
/* .ll-p{
  font-weight: 500;
  color: #5e5873;
} */
.ll-p p{
  margin-bottom: 0.2857rem;
}
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
}
.ll-Sedfloor{
  margin-bottom: 10px;
}
.ll-trackStatus{
  width: 80%;
  border: 1px solid #eee;
  height: 40px;
  display: flex;
}
.ll-colorSpan{
  display: inline-block;
  width: 2%;
  height: 39px;
  background: #4B7902;
}
.ll-desSpan{
  line-height: 40px;
  padding-left: 20px;
}
.ll-wrapper{
  display: flex;
}
.ll-wrap{
  width: 100%;
}
.ll-timeline{
  height: 58px;
}
.ll-tl{
 margin-bottom: 15px;
}
.ll-pt{
  margin: 20px 0;
}
</style>
