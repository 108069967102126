<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Fulfillment') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Outbound Order') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <!-- first floor -->
    <b-row>
      <b-col lg="3">
        <b-card class="card-browser-states ll-fHei ll-card">
          <b-card-body>
            <flat-pickr
              v-model="time"
              class="form-control ll-fpickr"
              :config="dateConfig"
              @input="onChange"
            />
            <b-input-group class="ll-searchBox">
              <b-input-group-prepend
                is-text
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-toggle.filterS
                class="ll-outline"
                v-if="false">
                <!-- Filter -->
                <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-form-input
                v-model="trackingIDs"
                class="searchInput"
                placeholder="Enter Order No to search"
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                :style="{'cursor': loadingList ? 'no-drop' : 'pointer'}"
                :disabled="loadingList"
                @click="searchById">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-card-body>
        </b-card>

        <!-- list -->
        <b-overlay :show="loadingList" rounded="sm">
        <div class="ll-cardHei">
          <template v-if="rows.length > 0">
          <b-card
            v-for="(item, index) in rows"
            :key="index"
            class="card-browser-states ll-card ll-cardList"
            :class="item.orderNo == selected ? 'bg-light' : 'bg-white'"
            @click="onDetail(item)"
            v-permission="[152]"
          >
            <b-card-header>
              <span class="ll-tkid">{{ item.orderNo }}</span>
              <span class="ll-tkid">{{ item.customerName }}</span>
              <!-- <span class="ll-tkid"></span> -->
            </b-card-header>
            <b-card-body class="ll-cardBody">
              <span>{{ item.orderCreationTime }}</span>
            </b-card-body>
          </b-card>
          </template>
          <div v-else class="ll-cardHei d-flex align-items-center justify-content-center">
            <h2>{{ $t('No order found') }}</h2>
          </div>
        </div>
        <!-- 分页 -->
        <div class="d-flex justify-content-between flex-wrap ll-page">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="size"
              :options="pageOptions"
              class="mx-1"
              @input="handlePageChange"
            />
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
            <span class="ll-text text-nowrap">total {{ totalSize }} entries </span>
          </div>
        </div>
        </b-overlay>
      </b-col>
      <b-col lg="9">
        <b-card class="card-browser-states ll-rightCard">
          <b-card-body>
            <b-tabs
              pills
              class="ll-tabs"
              v-permission="[154]"
              v-if="selectedItem"
            >
              <!-- Tab: Overview -->
              <b-tab active>
                <template #title>
                  <span class="d-none d-sm-inline">{{ $t('Overview') }}</span>
                </template>
                <out-tab-1 :details="details" :heiVal="heiVal"></out-tab-1>
              </b-tab>

              <!-- Tab: Item -->
              <b-tab>
                <template #title>
                  <span class="d-none d-sm-inline">{{ $t('Item') }}</span>
                </template>
                <out-tab-2 :details="details" :heiVal="heiVal"></out-tab-2>
              </b-tab>
            </b-tabs>
            <div class="display-4 ll-cardHei d-flex align-items-center justify-content-center" v-else>
              {{ $t('Please select an order to view') }}
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title=""
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item :title="$t('Status')">
            <v-select
              id="status"
              :options="statusList"
              placeholder="Please Select Status"
            ></v-select>
          </app-collapse-item>
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            v-b-toggle.filterS
            @click="queryList">
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, VBToggle, BTab, BTabs,
  BPagination, BFormSelect, BAvatar, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import outTab1 from './components/outTab1.vue'
import outTab2 from './components/outTab2.vue'
import { debounce } from "@/libs/fun.js"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    outTab1,
    outTab2,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BTable,
    flatPickr,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    vSelect,
    BFormTextarea,
    BTab,
    BTabs,
    BPagination,
    BFormSelect,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    permission,
  },
  data() {
    return {
      statusList: [],
      trackingIDs: '',
      dateConfig: {
        // dateFormat: 'Y-m-d H:i:ss',
        mode: 'range',
        defaultDate: [(new Date).setDate((new Date).getDate() - 2), new Date()],
        // enableTime: true, // 启用时间选择器
      },
      condition: {
        page: '1',
        size: '100',
        fromDateTime: null,
        toDateTime: null,
        orderNo: null,
        orderBy: 'orderCreationTime',
        orderMole: 0,
      },
      time: '',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null,
      size: 100,
      totalSize: null,
      totalPage: null,
      rows: [],
      orderStatus: '',
      orderStatusCode: {
        rec: '', // Order received
        prod: '', // In Production
        dip: '', // Delivery in progress
        comp: '', // Completed
      },
      orderId: '',
      selected: '',
      color: 'info',
      color1: 'primary',
      color2: 'danger',
      color3: 'secondary',
      tagsList: [],
      sPartyList: [],
      windowWidth: document.documentElement.clientWidth,  //屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //屏幕高度
      height: document.documentElement.clientHeight*0.8,
      height1: document.documentElement.clientHeight*0.67,
      hei: (document.documentElement.clientHeight*0.67-46)/10,
      heiVal: null,
      orderNo: null,
      hei1: document.documentElement.clientHeight*0.12,
      loadingList: true,
      selectedItem: null,
      details: null,
    }
  },
  computed: {
    styleVar() {
      return {
        '--cardbody-height': this.hei + 'px'
      }
    },
    styleVar1() {
      return {
        '--height': this.hei1 + 'px'
      }
    },
  },
  watch: {
    windowHeight(val) {
      this.height = val*0.8
      this.height1 = val*0.67
      this.hei = (val*0.67-46)/10
      this.heiVal = val*0.67
      this.hei1 = val*0.12
    },
    windowWidth(val) {
      // console.log('width', val)
    },
  },
  created() {},
  mounted() {
    this.getList()
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        window.fullWidth = document.documentElement.clientWidth
        this.windowHeight = window.fullHeight
        this.windowWidth = window.fullWidth
      })()
    }
  },
  methods: {
    // 默认日期格式转换
    dateFormats() {
      const fromDate = new Date()
      let datetime = fromDate.getFullYear() + "-" +
      (fromDate.getMonth()> 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
      + (fromDate.getDate()> 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
      + ' ' + '23:59:59'

      const toDate = new Date((new Date).setDate((new Date).getDate() - 2))
      let datetime1 = toDate.getFullYear() + "-" +
      (toDate.getMonth()> 8 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
      + (toDate.getDate()> 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))
      + ' ' + '00:00:00'

      this.condition.fromDateTime = datetime1
      this.condition.toDateTime = datetime
    },
    // 查询列表
    queryList() {
      this.$http.post('orderWms/search', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.loadingList = false
      }).catch(error => console.log(error))
    },
    // 首次查询
    getList() {
      this.dateConfig.dateFormat = 'Y-m-d'
      this.dateFormats()
      this.queryList()
    },
    // 根据日期查询
    onChange: debounce(function() {
      // this.dateConfig.dateFormat = 'Y-m-d H:i:ss'
      const dd = this.time.split('to ')
      if (dd[0] !== '') {
        this.condition.fromDateTime = (dd[1] !== undefined)? (dd[0] + '00:00:00') : (dd[0] + ' 00:00:00')
        this.condition.toDateTime = ((dd[1] !== undefined)? dd[1] : dd[0])  + ' 23:59:59'
      } else {
        this.condition.fromDateTime = null
        this.condition.toDateTime = null
      }
      this.queryList()
    }, 1000),
    // 根据ID查询
    searchById() {
      this.condition.orderNo = this.trackingIDs.trim()
      this.queryList()
    },
    handleChangePage(page) {
      this.condition.page = page
      this.queryList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.queryList()
    },
    onClear() {
    },
    // 点击查看详情
    onDetail(obj) {
      if (obj) {
        // this.orderNo = obj.id
        this.selected = obj.orderNo
        this.selectedItem = obj
        this.getDetail(obj.id)
      } else {
        // this.orderNo = ''
        this.selected = ''
        this.selectedItem = null
      }
    },
    getDetail(id){
      this.$http.get('orderWms/getDetail', { params: { id: id } }).then(res => {
        this.details = res.data.data
      })
    },
  },
}
</script>
<style scoped>
.ll-rightCard{
  height: 76vh;
}
.ll-badge{
  /* padding: 2px; */
  margin-right: 2px;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-fHei{
  height: 124px;
  /* height: var(--height); */
  margin-bottom: 10px;
}
.icon{
  width: 3em;
  height: 3em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.ll-conBox{
  display: flex;
  align-items: center;
}
.ll-box{
  margin-left: 10px;
}
.ll-box span{
  display: block;
  text-align: center;
}
.ll-box .ll-tit{
  font-size: 16px;
  font-weight: bold;
}
.ll-searchBox{
  margin-top: 10px;
}
.ll-fHei>.card-body{
  display: flex;
  align-items: center;
}
.ll-cardBody{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ll-card>.card-body{
  padding: 0;
  /* height: 70px; */
}
.ll-tabs .nav-pills .nav-link.active{
  background-color: unset;
  color: #5e5873;
  box-shadow: none;
  border: none;
  border-bottom: 3px solid #ed6d01;
  border-radius: 0px;
}
.ll-tabs .nav-item{
  font-weight: bold;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-tkid{
  color: #161d31;
}
.ll-cardList{
  margin-bottom: 5px;
  cursor: pointer;
}
.ll-cardList .card-header{
  padding: 0.5rem;
}
.ll-cardList .ll-cardBody{
  padding: 0.5rem;
}
.ll-cardHei{
  height: 62vh;
  overflow-y: auto;
}
/* .ll-page{
  position: absolute;
  bottom: -10px;
} */
.ll-cardActive{
  background: #f6f6f6;
}
.ll-backg{
  background: #f6f6f6;
}
.ll-text{
  margin: auto;
}
</style>
<style>
.ll-tabs .nav-item{
  font-weight: bold;
}
.ll-collapse .card{
  box-shadow: none;
}
</style>