<template>
  <section>
    <!-- <b-card class="card-browser-states">
      <b-card-body> -->
        <b-row class="ll-hr" :style="styleVar1">
          <b-col lg="3">
            <p class="l-pCon">{{rorderPkgItemDto.seqNo}}</p>
            <p class="l-pTit">{{ $t('Package Seq No') }}.</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{rorderPkgItemDto.trackingNo}}</p>
            <p class="l-pTit">{{ $t('Tracking No') }}</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{rorderPkgItemDto.actWeight}}</p>
            <p class="l-pTit">{{ $t('Net weight') }}</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{demension}}</p>
            <p class="l-pTit">{{ $t('Demension') }}(L x W x H)</p>
          </b-col>
        </b-row>
        <b-table
          :items="item" :fields="fields"
          :borderless='false' :hover="true"
          class="ll-tableHei ll-table"
          :sticky-header="true"
          :style="styleVar">
          <template #cell(itemCode)="scope">
            <p class="ll-mulP">{{ scope.value }}</p>
            <p class="ll-mulP">{{ scope.item.itemTitle }}</p>
          </template>
        </b-table>
      <!-- </b-card-body>
    </b-card> -->
  </section>
</template>

<script>
import {
    BCard, BCardBody, BTable,
    BRow, BCol,
} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
  },
  props: {
    heiVal: Number,
    details: Object,
  },
  data() {
    return {
      item: [],
      fields: [
        { key: 'itemCode', label: this.$t('Item') },
        // { key: 'qty', label: 'Quantity' },
        { key: 'itemCode', label: this.$t('Unit') },
        { key: 'unitPrice', label: this.$t('Unit Price') },
        { key: 'currency', label: this.$t('Currency') },
        { key: 'qty', label: this.$t('Outbound qty') },
        { key: 'batchCode', label: this.$t('Batch No') },
      ],
      rorderPkgItemDto: {
        seqNo: '',
        trackingNo: '',
        actWeight: '',
        actWidth: '',
        actHeight: '',
        actLength: '',
      },
      demension: '',
      tableHei: '',
      windowHeight: document.documentElement.clientHeight*0.67,
    }
  },
  computed: {
    styleVar() {
      return {
        '--height': this.windowHeight*0.6 + 'px'
      }
    },
    styleVar1() {
      return {
        '--height': this.windowHeight*0.1 + 'px'
      }
    },
  },
  watch: {
    details(val) {
      this.getInfo(val)
    },
    heiVal(val) {
      if (val < 627) {
        this.tableHei = (this.windowHeight*0.6) + 'px'
      } else {
        this.tableHei = (this.windowHeight*0.8) + 'px'
      }
    },
  },
  created() {},
  methods: {
    getInfo(info){
      this.item = info.detailList
      if (info.rorderPkgItemDto !== null) {
        this.rorderPkgItemDto = info.rorderPkgItemDto
        this.demension = `${info.rorderPkgItemDto.actLength} X ${info.rorderPkgItemDto.actWidth} X ${info.rorderPkgItemDto.actHeight} `
      } else {
        this.rorderPkgItemDto.seqNo = ''
        this.rorderPkgItemDto.trackingNo = ''
        this.rorderPkgItemDto.actWeight = ''
        this.demension = ''
      }
    },
    getDetail(id) {
      this.$http.get('orderWms/getDetail', { params: { id: id } }).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          // console.log('res1', info)
          this.item = info.detailList
          if (info.rorderPkgItemDto !== null) {
            this.rorderPkgItemDto = info.rorderPkgItemDto
            this.demension = `${info.rorderPkgItemDto.actLength} X ${info.rorderPkgItemDto.actWidth} X ${info.rorderPkgItemDto.actHeight} `
          } else {
            this.rorderPkgItemDto.seqNo = ''
            this.rorderPkgItemDto.trackingNo = ''
            this.rorderPkgItemDto.actWeight = ''
            this.demension = ''
          }
        }
      }).catch(error => console.log(error))
    },
  },
}
</script>
<style scoped>
.l-pCon{
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  margin-bottom: 0;
}
/* .l-pTit{
  height: 36px;
} */
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
  margin-bottom: 10px;
  height: var(--height1);
}
.ll-mulP{
  margin: 0;
}
.ll-tableHei{
  height: var(--height);
  max-height: unset;
}
</style>
<style>
[dir] .ll-tableHei:not(.table-dark):not(.table-light) thead:not(.thead-dark) th{
  background-color: unset !important;
}
</style>
