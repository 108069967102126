// import store from '@/store'

function checkPermission(el, binding) {
  const { value } = binding
  // const roles = store.getters && store.getters.roles
  const userData = JSON.parse(localStorage.getItem('userInfo'))
  const arr = []
  const nameArr = []
  userData.buttonPermissions.map(i=>{
    arr.push(i.id)
    arr.push(i.name)
  })

  const roles = arr
  // console.log(arr)
  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionRoles = value

      const hasPermission = roles.some(role => {
        return permissionRoles.includes(role)
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
        // alert("You don't have access to this page!");
      }
      return hasPermission
    }
  } else {
    throw new Error(`need roles! Like v-permission="['admin','editor']"`)
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  }
}
